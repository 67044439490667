<template>
  <div class="add-item-container">
    <uds-icon
      class="icon-add"
      icon-name="add"
      :color="udsColors.udsWhite"
      size="xsmall"
      :style="{ backgroundColor: color }"
    />
    <span :style="{ color }">{{ label }}</span>
  </div>
</template>

<script setup lang="ts">
const udsColors = getUdsColors();

withDefaults(
  defineProps<{
    label: string;
    color?: string;
  }>(),
  { color: getUdsColors().udsGoogleBlue },
);
</script>

<style lang="scss" scoped>
.add-item-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
  cursor: pointer;
  width: max-content;

  .icon-add {
    border-radius: 50%;
    padding: 1px;
    margin-right: 11px;
  }

  span {
    font-weight: 100;
    font-size: 16px;
  }
}
</style>
